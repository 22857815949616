import styled from "styled-components"
import send from "src/assets/images/home/send.png"
import close from "src/assets/images/home/close.png"
import chatIcon from "src/assets/images/Ai/home-icon.jpg"
export const ChatBotStyled = styled.div`
  // loading
  .rcw-header {
    padding-top: 12px !important;
    border-radius: 10px 10px 0 0 !important;
  }
  /* button hiển thị icon chat */
  button.rcw-picker-btn {
    display: none;
  }
  .rcw-sender button {
    background-color: unset;
    margin-left: 10px;
    margin-right: 12px;
  }
  /*  icon send*/
  button.rcw-send {
    background-image: url(${send});
  }
  .rcw-send-icon {
    visibility: collapse;
  }
  .rcw-sender {
    width: auto !important;
    justify-content: center;
    padding: 0;
    border-radius: 10px 10px 10px 10px;
    margin: 15px;
  }
  .rcw-conversation-container .avatar {
    width: 50px;
    height: 50px;
  }
  .rcw-title {
    display: flex;
    margin-left: 10px !important;
    justify-content: center;
  }
  .rcw-conversation-container .rcw-title {
    padding-bottom: 0;
    font-size: 30px;
    display: flex;
    align-items: center;
  }
  .rcw-sender {
    align-items: center;
    width: 100%;
    background-color: white;
  }
  .rcw-new-message {
    width: 90%;
    background-color: unset;
  }
  .rcw-conversation-container .rcw-header {
    padding: 0px 0 15px;
  }
  .rcw-conversation-container {
    box-shadow: none;
  }
  .rcw-messages-container {
    background-color: #e9e9e9;
    background-position: center;
  }
  div#rcw-conversation-container {
    background-color: #e9e9e9;
  }
  .rcw-close-button {
    background-image: url(${close});
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-position: center;
    height: 25px;
  }
  .rcw-close-button:hover {
    cursor: pointer;
  }
  .rcw-conversation-container .rcw-close-button {
    background-color: #35cce6;
    border: 0;
    display: block;
    position: absolute;
    right: 1px;
    top: 5px;
    width: 40px;
  }

  .rcw-close-button img {
    visibility: hidden;
  }
  /* end */
  .chat-container {
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .rcw-new-message {
    position: relative;
    padding-left: 40px; /* Adjust padding to make space for the icon */
  }

  .rcw-new-message::before {
    content: "";
    background: url(${chatIcon}) no-repeat center center;
    background-size: contain;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px; /* Width of the icon */
    height: 20px; /* Height of the icon */
  }

  .rcw-new-message-input {
    padding-left: 40px; /* Adjust padding to make space for the icon */
  }
  .rcw-conversation-container > .rcw-header {
    /* background-color: #35c389; */
    background-color: ${props => (!!props.color ? props.color : "#35c389")};
  }
  button.rcw-launcher.rcw-hide-sm {
    background-color: ${props => (!!props.color ? props.color : "#35c389")};
  }
  .rcw-hide-sm {
    display: block !important;
  }
  button.rcw-launcher {
    background-color: ${props => (!!props.color ? props.color : "#35c389")};
  }
  div#rcw-conversation-container {
    height: 80%;
  }
  /* loading */
  .loader-container {
    text-align: center;
    margin-left: 47px;
  }
`
