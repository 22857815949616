const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/:id",
  CHAT_FROM: "/",

  //ANONYMOUS

  // ADMIN
}
export default ROUTER
