import { Col, Row, Spin } from "antd"
import React, { useEffect, useState } from "react"
import logo from "src/assets/images/logo/logolld.png"
import aibot from "src/assets/images/home/AI_robot.png"
import { DisplayStyle } from "src/pages/ANONYMOUS/HomeBackground/styled"
import BoxChat from "../BoxChat"
import { BallTriangle } from "react-loader-spinner"
import useWindowSize from "src/lib/useWindowSize"
import LazyLoad from "react-lazyload"

const HomeBackground = () => {
  const isMobile = useWindowSize.isMobile()
  const [loading, setLoading] = useState(true)
  const handleImageLoad = () => {
    console.log("check")
    setLoading(false) // Đã tải xong ảnh, set loading thành false
  }
  useEffect(() => {
    // Giả sử không có hình ảnh nào cần tải từ mạng, chúng ta sẽ đặt loading thành false ngay lập tức
    setLoading(false)
  }, []) // Chỉ gọi một lần khi component được mount
  return (
    <DisplayStyle>
      <BoxChat />
    </DisplayStyle>
  )
}

export default HomeBackground
