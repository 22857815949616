import axios from "axios"
import React, { useEffect, useState, useRef } from "react"
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  toggleMsgLoader,
  toggleWidget,
} from "react-chat-widget"
import "react-chat-widget/lib/styles.css"
import { ChatBotStyled } from "./styled"
import logo from "src/assets/images/Ai/download.png"
import logoUser from "src/assets/images/home/3d_avatar_26.png"
import useWindowSize from "src/lib/useWindowSize"
import SvgIcon from "src/components/SvgIcon"
import AiService from "src/services/AiService"
import { useParams } from "react-router-dom"
import sha256 from "crypto-js/sha256"

const BoxChat = () => {
  const [loading, setLoading] = useState(false)
  const isDesktop = useWindowSize.isDesktop()
  const [dataBot, setDataBot] = useState()
  const [parentUrl, setParentUrl] = useState("")
  const [suggestionSent, setSuggestionSent] = useState(false)
  const { id } = useParams()
  const [chatId, setChatId] = useState()

  const baseURL =
    "https://survive-pentium-ceiling-last.trycloudflare.com/api/qa/"
  const timerRef = useRef(null)
  // const getDetailAi = async newMessage => {
  //   try {
  //     setLoading(true)
  //     // const res = await axios.get(baseURL + `?question=${newMessage}`)
  //     // Loại bỏ dấu ngoặc kép
  //     const res = await AiService.getChatBotByID({
  //       chatbot_id: !!id ? id : undefined,
  //     })
  //     if (res?.isOk) {
  //     }
  //   } finally {
  //     setLoading(false)
  //   }
  // }
  const handleNewUserMessage = async newMessage => {
    try {
      setLoading(true)
      toggleMsgLoader()
      // const res = await axios.get(baseURL + `?question=${newMessage}`)
      let hexString = sessionStorage.getItem("session_id")
      // Loại bỏ dấu ngoặc kép
      const cleanString = hexString.replace(/"/g, "")
      const res = await AiService.ChatBot({
        question: newMessage,
        session_id: cleanString,
        custom_source: "ok",
        chatbot_id: chatId,
      })
      if (res?.isOk) {
        const htmlString = res?.answer.replaceAll("\n\n\n", "\n")
        addResponseMessage(htmlString)
      }
      resetTimer()
    } catch (error) {
      console.error("Lỗi khi gọi API:", error)
      addResponseMessage(
        "Hệ thống đang bảo trì. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.",
      )
    } finally {
      setLoading(false)
      toggleMsgLoader()
    }
  }

  // !lấy địa chỉ ip của máy
  const fetchIpAddress = async () => {
    try {
      setLoading(true)
      const response = await axios.get("https://api.ipify.org?format=json")
      if (!!response.data.ip) {
        let plaintext = response.data.ip + new Date()
        // setIpAddress(sha256(plaintext).toString())
        sessionStorage.setItem(
          "session_id",
          JSON.stringify(sha256(plaintext).toString()),
        )
      }
    } catch (error) {
      console.error("Error fetching IP address:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log("check local:", window)
    if (!sessionStorage.getItem("session_id")) {
      fetchIpAddress()
    }
    // đóng vở khi vừa bật lên
    if (dataBot?.is_visible == 1) {
      toggleWidget()
    }
    getDataChat(window)
    if (dataBot) {
    }
    // Lấy chuỗi query parameters từ URL
    // const urlParams = new URLSearchParams(window.location.search)
    // const parentUrlParam = urlParams.get("parentUrl")
    // const decodedUrl = decodeURIComponent(window.location.href)
    // console.log("check params", decodedUrl, urlParams)

    // setParentUrl(parentUrlParam)

    // Set up the inactivity timer
    startTimer()

    return () => {
      // Clean up the timer on component unmount
      clearTimeout(timerRef.current)
    }
  }, [])

  const startTimer = () => {
    if (suggestionSent) return // If suggestion is already sent, don't restart the timer

    timerRef.current = setTimeout(() => {
      addResponseMessage(
        `Xin lỗi, không biết anh chị quan tâm đến sản phẩm gì của chúng tôi ah. Anh chị có thể để lại câu hỏi để được hỗ trợ không ạ?`,
      )
      setSuggestionSent(true)
    }, 60000) // 5 minutes = 300000 ms
  }

  const resetTimer = () => {
    clearTimeout(timerRef.current)
    if (dataBot) {
      startTimer()
    }
  }
  const getDataChat = async event => {
    try {
      setLoading(true)

      const resp = await AiService.getChatBotByID({
        domain: event?.location.hostname,
      })
      if (resp?.isOk) {
        setChatId(resp?.data?.id)
        setDataBot(resp?.data)
        addResponseMessage(
          `Chào Anh chị. Tôi là ${resp?.data?.name}. Không biết nhu cầu của anh, chị là gì ạ?`,
        )
      }
    } catch (error) {
      console.error("Lỗi khi gọi API:", error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <ChatBotStyled color={dataBot?.color}>
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title={dataBot?.name || "Trợ Lý VieAi"}
        subtitle={false}
        typing={loading}
        showCloseButton={true}
        autofocus={true}
        badge={0}
        profileAvatar={dataBot?.avatar || logo}
        profileClientAvatar={logoUser}
        showQuickButtons={false}
        senderPlaceHolder="Nhập câu chat"
        sendButtonAlt={<SvgIcon name="arrow-send" />}
      />
      {loading && (
        <p style={{ textAlign: "center", fontStyle: "italic" }}>...</p>
      )}
    </ChatBotStyled>
  )
}

export default BoxChat
