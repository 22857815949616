import styled from "styled-components"
import background from 'src/assets/images/home/background.png';

export const DisplayStyle = styled.div`
    .img-back{
        background-image: url(${background});
        background-size: cover; /* Để ảnh phủ đầy phần nền */
        background-position: center; /* Để căn giữa ảnh */  
        min-height: 100vh;
    }
    .group-img{
        margin: 30px 0 0 50px;
    }
    .group-title{
        display: flex;
        align-items: center;
        color: white;
    }
    .text-title{
        font-size: 27.5px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .text-note{
        font-weight: 300;
        opacity: 0.8;
    }
    /* mobile */
@media (max-width:480px) {
    .text-title{
        font-size: 25.5px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .group-img{
        margin: 10px 2px 0px 2px;
        padding: 0 8px;
    }
    .group-title{
        width: 100vh;
    }
    .text-note {
        font-size: 13px;    
    }
}
`