import axios from "axios"
import http from "../index"
import { apiGetChatBotInfo, apiChatBot, apiGetChatBotByID } from "./urls"
import QueryString from "qs"

const GetChatBotInfo = params => http.get(apiGetChatBotInfo, { params })
const ChatBot = body => http.post(apiChatBot, body)
const getChatBotByID = params => http.get(apiGetChatBotByID, { params })

const AiService = {
  getChatBotByID,
  ChatBot,
  GetChatBotInfo,
}
export default AiService
